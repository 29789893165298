import { initRuntime, DafAuthConfig, DafInMemoryCacheStrategy, loadApp } from '@nab/x-runtime';
import { getShellActions } from '@nab/x-runtime';
//import React from 'react';
import * as config from './config.json';


export default async function startShell() {
    try {
       await  initRuntime({
            authTokenCacheStrategy: new DafInMemoryCacheStrategy(),
            authConfig: {
                environment: 'dev',
                context: 'external',
                requestTokenOnLoad: false,
            },
            userConfig: {
                type: 'customer',
                org: 'NAB',
           },
       }).then(() => {
           getShellActions().addEventListener('user_authenticated', (data) => {
                // user authenticated, here is the bearer token
               if (data) {
                    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                    document.cookie = `Bearer=${data.bearerToken};path=/`;
                }
           });
           getShellActions().addEventListener('sign_out', () => {
               Console.log('Logged out');
           });
           loadApp({
               appMetaData: config.LoginAppMetaData,
               appConfig: config.LoginAppConfig,
               hostElement: document.getElementById('Login'),
           });
          loadApp({
              appMetaData: config.ChangePwdAppMetaData,
              appConfig: config.ChangePwdAppConfig,
              hostElement: document.getElementById('ChangePassword'),
          });
           loadApp({
               appMetaData: config.LogoutAppMetaData,
               appConfig: config.LogoutAppConfig,
               hostElement: document.getElementById('Logout'),
           });
       })

    } catch (e) {
        console.log(e);
    }
}

export function LoginSubscribe(callback) {
    getShellActions().addEventListener('user_authenticated', (data) => {
        // user authenticated, here is the bearer token
        if (data) {
            document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
            document.cookie = `Bearer=${data.bearerToken};path=/`;
        }
        callback(data);
        
    });
}

export function LogoutSubscribe(callback) {
    getShellActions().addEventListener('sign_out', () => {
        Console.log('Logged out - callback');
        callback();
    });
}

//export function ManualLogout(callback) {
//    let name = "Bearer=";
//    let token = "";
//    let ca = document.cookie.split(';');
//    for (let i = 0; i < ca.length; i++) {
//        let c = ca[i];
//        while (c.charAt(0) == ' ') {
//            c = c.substring(1);
//        }
//        if (c.indexOf(name) == 0) {
//            token = c.substring(name.length, c.length);
//        }
//    }

//    var data = JSON.stringify({ "client_id": config.ManualLogoutConfig.clientId, "token": token, "token_type_hint": config.ManualLogoutConfig.token_type_hint });

//    var xhr = new XMLHttpRequest();
//    xhr.withCredentials = true;

//    xhr.addEventListener("readystatechange", function () {
//        if (this.readyState === 4) {
//            console.log("Logged Out: " + this.responseText);
//            document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
//        }
//    });

//    xhr.open("POST", config.ManualLogoutConfig.url);
//    xhr.setRequestHeader("Content-Type", "application/json");
//    xhr.setRequestHeader("x-nab-cgw", "customer");

//    xhr.send(data);
//    if (callback) callback();
//}


LoginSubscribe.bind(document);
document.userAuthenticationCallback = LoginSubscribe;


LogoutSubscribe.bind(document);
document.userLogoutCallback = LogoutSubscribe;

//ManualLogout.bind(document);
//document.manualLogoutCallback = ManualLogout;