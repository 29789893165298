import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import startShell from './PreLoad';

startShell().then(() => {
    window.React = React;
    ReactDOM.render(
        <div> </div>,
        document.getElementById('root')
    )
});
